import ApiService from '@/util/api.service';

export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: 'Nombre',
    type: String,
  },
  email: {
    label: 'Nombre de Usuario',
    type: String,
  },
  password: {
    label: 'Contraseña',
    type: String,
    visibility: (item) => item.id === undefined,
    password: true,
  },
  profile: {
    label: 'Perfil',
    type: String,
    model: 'v2/profiles',
    autocomplete: false,
    changeOnLoad: false,
    onChange: (val, fields) => {
      if (val) {
        const option = fields.profile.getOption()
        fields.profileName.value = option.name
      } else {
        fields.profileName.value = ''
      }
    },
  },
  profileName: {
    label: 'Perfil',
    type: String,
    visibility: false,
  },
  workUnit: {
    label: 'Unidad de trabajo',
    type: String,
    autocomplete: true,
    onChange: async (val, fields) => {
      try {
        const rest = await ApiService({
          url: 'users/workunits',
          method: 'get',
        });
        rest.sort();
        fields.workUnit.setOptions(
          rest.filter((a) => {
            if (!a || a.length === 0) return false;
            if (!val || val.length === 0) return true;
            try {
              return a.toUpperCase().includes(val.toUpperCase());
            } catch (e) {
              return false;
            }
          })
        );
      } catch (e) {
        console.log(e);
      }
    },
    // options: ['GUA'],
    // onChange: (val, fields) => {
    //   if (val > 2) {
    //     ApiService({
    //       url: 'users/workunits',
    //       method: 'get'
    //     }).then((res) => {
    //       fields.workunit.setOptions(res.filter((a) => a))
    //     })
    //   }
    // }
  },
  // Preferencias
  internet: {
    label: 'Acceso desde internet',
    type: String,
    options: [
      { id: 'si', name: 'Si' },
      { id: 'no', name: 'No' },
    ],
  },
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true,
  },
  _location: {
    label: 'Ubicación del mapa',
    type: String,
    location: true,
    fields: ['latitude', 'longitude'],
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    decimals: true,
    optional: true,
    visibility: false,
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    decimals: true,
    optional: true,
    visibility: false,
  },
  zoom: {
    label: 'Zoom',
    type: Number,
    default: 5,
    optional: true,
  },
  // Permisos
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true,
  },
  administrator: {
    label: 'Es administrador?',
    type: Boolean,
    optional: true,
  },
  readonly: {
    label: 'Sólo lectura',
    type: Boolean,
    optional: true,
  },
  deviceReadonly: {
    label: 'Unidad de sólo lectura',
    type: Boolean,
    optional: true,
  },
  limitCommands: {
    label: 'Limitar comandos',
    type: Boolean,
    optional: true,
  },
  permission_murphy: {
    label: 'Permiso a Murphy',
    type: Boolean
  },
  permission_reportantes: {
    label: 'Permiso a reportantes (Widget Alertas)',
    type: Boolean
  },
  permission_sinaloaSOS: {
    label: 'Permiso a Sinaloa SOS',
    type: Boolean
  },
  expirationTime: {
    label: 'Vencimiento',
    type: Date,
    optional: true,
  },
  deviceLimit: {
    label: 'Límite del unidades',
    type: Number,
    optional: true,
  },
  userLimit: {
    label: 'Límite de usuario',
    type: Number,
    optional: true,
  },
  ptz_control: {
    label: 'Control PTZ',
    type: Boolean,
    optional: true,
  },
  alarm_sensor: {
    label: 'Alarmar botones de pánico',
    type: Boolean,
    optional: true,
  },
  _socialSeparator: {
    label: 'Datos',
    type: String,
    separator: true,
    optional: true,
  },
  token: {
    label: 'Token',
    type: String,
    generated: true,
  },
  _whatsappSeparator: {
    label: 'Notificaciones Murphy',
    type: String,
    separator: true,
    optional: true,
  },
  whatsappId: {
    label: 'Identificador de Murphy',
    type: String,
    number: true,
    optional: true,
  },
  keywordsWhatsapp: {
    label: 'Palabras clave',
    type: String,
    uppercase: true,
    optional: true,
  },
  btnAlertsWhatsapp: {
    label: 'Alertas de Botones',
    type: Boolean,
    optional: true,
  },
  cameraAlertsWhatsapp: {
    label: 'Alertas de Camaras',
    type: Boolean,
    optional: true,
  },
  incidentsAlertsWhatsapp: {
    label: 'Alertas de Incidentes',
    type: Boolean,
    optional: true,
  },
  _adminSeperator: {
    label: 'Acceso a Módulos de Administración',
    type: String,
    separator: true,
    optional: true,
  },
  permission_groups: {
    label: 'Permiso a Grupos',
    type: Boolean,
    optional: true,
  },
  permission_cameras: {
    label: 'Permiso a Camaras',
    type: Boolean,
    optional: true,
  },
  permission_bus: {
    label: 'Permiso a Transporte',
    type: Boolean,
    optional: true,
  },
  permission_geofences: {
    label: 'Permiso a Geocercas',
    type: Boolean,
    optional: true,
  },
  permission_lpr: {
    label: 'Permiso a LPRs',
    type: Boolean,
    optional: true,
  },
  permission_sensors: {
    label: 'Permiso a Botones de Pánico',
    type: Boolean,
    optional: true,
  },
  permission_devices: {
    label: 'Permiso a Dispositivos GPS',
    type: Boolean,
    optional: true,
  },
  permission_calendars: {
    label: 'Permiso a Calendarios',
    type: Boolean,
    optional: true,
  },
  permission_scenarios: {
    label: 'Permiso a Escenarios',
    type: Boolean,
    optional: true,
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true,
  },
};
